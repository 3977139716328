import React, { useState } from "react";
import { keys, includes } from 'lodash';
import useDimensions from 'react-use-dimensions';
import { IntlProvider } from "react-intl";

import "./App.scss";
import logo from "./logo.svg";
import background from "./background.png";
import translations from "./translations";
import Navigation from "components/nav";
import Home from "containers/home/Home";
import Services from "containers/services/Services";
import Why from "containers/why/Why";
import Customers from "containers/customers/Customers";
import Contact from "containers/contact/Contact";
import Footer from "components/footer/Footer";

const availableLanguages = keys(translations);
let browserLanguage = (window.navigator.userLanguage || window.navigator.language);
if (browserLanguage) {
	browserLanguage = browserLanguage.split('-')[0];
	if (!includes(availableLanguages, browserLanguage)) {
		browserLanguage = null;
	}
}

const defaultLanguage = browserLanguage || 'en';

const App = () => {
	const [currentLanguage, setCurrentLanguage] = useState(defaultLanguage);
	let messages = translations[currentLanguage];
	const [ref, dimensions] = useDimensions();
	function browserSize() {
		const width = dimensions.width;
		if (width <= 576) {
			return 'xs';
		} else if (width <= 801) {
			return 'sm';
		} else if (width <= 992) {
			return 'md';
		} else if (width <= 1200) {
			return 'lg';
		} else {
			return 'xl';
		}
	};

	function changeLanguage(language) {
		if (includes(availableLanguages, language)) {
			setCurrentLanguage(language);
		}
	}

	return (
		<IntlProvider key={currentLanguage} locale={currentLanguage} messages={messages}>
			<div id="app" ref={ref}>
				<section id="splash">
					<div id="background-image">
						<img src={background} alt="Background"/>
					</div>
					<div id="splash-background">
						<img src={logo} alt="Logo"/>
					</div>
					<Navigation />
				</section>
				<div className="content">
					<Home browserSize={browserSize()}/>
					<Services />
					<Why />
					<Customers />
					<Contact />
					<Footer changeLanguage={changeLanguage} currentLanguage/>
				</div>
			</div>
		</IntlProvider>
	);
}

export default App;
