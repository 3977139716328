export default [
	{
		image: require("../images/categories/all.svg"),
		title: {
			en: 'All',
			es: 'Todos'
		},
		tags: ['branding', 'editorial', 'illustration', 'games', 'video', 'animation']
	},
	{
		image: require("../images/categories/branding.svg"),
		title: {
			en: 'Branding',
			es: 'Marca'
		},
		tags: ['branding']
	},
	{
		image: require("../images/categories/editorial.svg"),
		title: {
			en: 'Editorial',
			es: 'Editorial'
		},
		tags: ['editorial']
	},
	{
		image: require("../images/categories/illustration.svg"),
		title: {
			en: 'Illustration',
			es: 'Ilustración'
		},
		tags: ['branding']
	},
	{
		image: require("../images/categories/games.svg"),
		title: {
			en: 'Games',
			es: 'Juegos'
		},
		tags: ['games']
	},
	{
		image: require("../images/categories/video.svg"),
		title: {
			en: 'Animation/Video',
			es: 'Animación/Video'
		},
		tags: ['video', 'animation']
	}
];
