import React from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";

import "./Home.scss";
import light from "./light.svg";
import { projects } from '../../content';

function Home(props) {
	const intl = props.intl;
	const browserSize = props.browserSize;

	const slidePercentage = function getSlidePercentage() {
		switch (browserSize) {
			case 'xs':
			case 'sm':
				return 100;
			case 'md':
				return 50;
			default:
				return 33;
		}
	}();

	const carouselLabels = {
		leftArrow: intl.formatMessage({ id: 'home.back'}),
		rightArrow: intl.formatMessage({ id: 'home.next'})
	};

	return (
		<section id="home">
			<div className="welcome">
				<FormattedMessage id="home.header" tagName="h1"/>
				<img src={light} alt="Light"/>
				<FormattedMessage id="home.welcome" tagName="p"/>
				<FormattedMessage id="home.text" tagName="p"/>
			</div>
			<br />
			<div className="recent-projects">
				<FormattedMessage id="home.recent-projects" tagName="h1"/>
				<Carousel infiniteLoop centerMode centerSlidePercentage={slidePercentage}
					labels={carouselLabels} showThumbs={false} showStatus={false}>
					{
						projects.map((project, i) => {
							return (
								<img key={i} src={project['image']} alt="Project"/>
							);
						})
					}
				</Carousel>
			</div>
		</section>
	);
}

export default injectIntl(Home);
