export default {
	branding: {
		en: 'Branding',
		es: 'Marca'
	},
	editorial: {
		en: 'Editorial',
		es: 'Editorial'
	},
	illustration: {
		en: 'Illustration',
		es: 'Ilustración'
	},
	games: {
		en: 'Games',
		es: 'Juegos'
	},
	video: {
		en: 'Video',
		es: 'Vídeo'
	},
	animation: {
		en: 'Animation',
		es: 'Animación'
	}
};
