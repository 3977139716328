import React, { useState } from "react";
import { FormattedMessage } from "react-intl";

import "./Customers.scss";
import customer from './customer.svg';
import { testimonials } from '../../content';

function Customers() {
	const [visibleTestimonial, setVisibleTestimonial] = useState(0);

	return (
		<section id="customers">
			<div className="about">
				<img src={customer} alt="customer"/>
				<div className="text">
					<FormattedMessage id="customers.title.before" tagName="h3"/>
					<FormattedMessage id="customers.title" tagName="h2"/>
					<FormattedMessage id="customers.text" tagName="p"/>
					<FormattedMessage id="customers.text.after" tagName="p"/>
				</div>
			</div>
			<div className="testimonials">
				{
					testimonials.map((testimonial, i) => {
						return (
							<div key={i} {...{className: 'testimonial' + (i === visibleTestimonial ? ' visible' : '')}}>
								<img src={testimonial['image']} alt="Testimonial"/>
								<p>"<FormattedMessage id={"testimonials.quote." + i}/>"</p>
								<h4>{ testimonial['name'] }</h4>
							</div>
						);
					})
				}
			</div>
		</section>
	);
}

export default Customers;
