export default [
	{
		image: require("../images/projects/placeholder.png"),
		name: {
			en: "Clixy 1",
			es: "Español 1"
		},
		tags: ['branding', 'video']
	},
	{
		image: require("../images/projects/placeholder.png"),
		name: {
			en: "Clixy 2",
			es: "Español 2"
		},
		tags: ['animation', 'games']
	},
	{
		image: require("../images/projects/placeholder.png"),
		name: {
			en: "Clixy 3",
			es: "Español 3"
		},
		tags: ['illustration', 'games']
	},
	{
		image: require("../images/projects/placeholder.png"),
		name: {
			en: "Clixy 4",
			es: "Español 4"
		},
		tags: ['video', 'editorial']
	},
	{
		image: require("../images/projects/placeholder.png"),
		name: {
			en: "Clixy 5",
			es: "Español 5"
		},
		tags: ['branding', 'animation']
	},
	{
		image: require("../images/projects/placeholder.png"),
		name: {
			en: "Clixy 6",
			es: "Español 6"
		},
		tags: ['video', 'illustration']
	},
	{
		image: require("../images/projects/placeholder.png"),
		name: {
			en: "Clixy 8",
			es: "Español 8"
		},
		tags: ['animation', 'games']
	},
	{
		image: require("../images/projects/placeholder.png"),
		name: {
			en: "Clixy 9",
			es: "Español 9"
		},
		tags: ['animation', 'games']
	},
]
