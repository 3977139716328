import React from "react";
import { FormattedMessage, injectIntl } from 'react-intl';

import { languages } from '../../translations';
import "./Footer.scss";
import logo from "./logo.svg";
import facebookIcon from "./facebook_icon.svg";
import linkedinIcon from "./linkedin_icon.svg";
import instagramIcon from "./instagram_icon.svg";

const Footer = (props) => {
	return (
		<section id="footer">
			<img className="logo" src={logo} alt="Logo"/>
			<div className="social">
				<a href="#footer">
					<img src={facebookIcon} alt="Facebook"/>
				</a>
				<a href="#footer">
					<img src={instagramIcon} alt="Instagram"/>
				</a>
				<a href="#footer">
					<img src={linkedinIcon} alt="LinkedIn"/>
				</a>
			</div>
			<div className="links">
				<p>&copy; 2011 - {new Date().getFullYear()}</p>
				<a href="#footer" target="_blank">
					<FormattedMessage id="footer.terms"/>
				</a>
				<a href="#footer" target="_blank">
					<FormattedMessage id="footer.privacy"/>
				</a>
			</div>
			<div className="language">
				<FormattedMessage id="footer.language" tagName="p"/>
				<select onChange={(e) => props.changeLanguage(e.target.value)} defaultValue={props.intl.locale}>
					{
						languages.map((language, i) => {
							return (
								<option value={language['id']} key={i}>{language['name']}</option>
							);
						})
					}
				</select>
			</div>
		</section>
	);
}

export default injectIntl(Footer);
