import React from "react";
import { FormattedMessage } from "react-intl";

import "./Services.scss";

import writing from "./writing.svg";
import web from "./web.svg";
import video from "./video.svg";
import photo from "./photo.svg";

function Services() {
	return (
		<section id="services">
			<h1>
				<FormattedMessage id="services.title" />
			</h1>
			<div className="services">
				<div className="service">
					<img src={writing} alt="Pencil" />
					<div className="title">
						<FormattedMessage id="services.design.title" tagName="h3"/>
					</div>
					<div className="details">
						<p>
							<FormattedMessage id="services.design.p1"/>
						</p>
						<p>
							<FormattedMessage id="services.design.p2" />
						</p>
						<p>
							<FormattedMessage id="services.design.p3" />
						</p>
						<p>
							<FormattedMessage id="services.design.p4" />
						</p>
					</div>
				</div>
				<div className="service">
					<img src={web} alt="Pencil" />
					<div className="title">
						<FormattedMessage id="services.dev.title" tagName="h3"/>
					</div>
					<div className="details">
						<p>
							<FormattedMessage id="services.dev.p1" />
						</p>
						<p>
							<FormattedMessage id="services.dev.p2" />
						</p>
						<p>
							<FormattedMessage id="services.dev.p3" />
						</p>
						<p>
							<FormattedMessage id="services.dev.p4" />
						</p>
					</div>
				</div>
				<div className="service">
					<img src={video} alt="Pencil" />
					<div className="title">
						<FormattedMessage id="services.video.title" tagName="h3"/>
					</div>
					<div className="details">
						<p>
							<FormattedMessage id="services.video.p1" />
						</p>
						<p>
							<FormattedMessage id="services.video.p2" />
						</p>
						<p>
							<FormattedMessage id="services.video.p3" />
						</p>
						<p>
							<FormattedMessage id="services.video.p4" />
						</p>
					</div>
				</div>
				<div className="service">
					<img src={photo} alt="Pencil" />
					<div className="title">
						<FormattedMessage id="services.photo.title" tagName="h3"/>
					</div>
					<div className="details">
						<p>
							<FormattedMessage id="services.photo.p1" />
						</p>
						<p>
							<FormattedMessage id="services.photo.p2" />
						</p>
						<p>
							<FormattedMessage id="services.photo.p3" />
						</p>
						<p>
							<FormattedMessage id="services.photo.p4" />
						</p>
					</div>
				</div>
			</div>
		</section>
	);
}

export default Services;
