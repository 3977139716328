import React from "react";
import { FormattedMessage } from "react-intl";

import "./Why.scss";
import powerline from "./powerline.jpg";

function Why() {
	return (
		<section id="why">
			<div className="text">
				<FormattedMessage id="why.why.title" tagName="h2"/>
				<FormattedMessage id="why.why.text" tagName="p"/>
				<FormattedMessage id="why.what.title" tagName="h2"/>
				<FormattedMessage id="why.what.text" tagName="p"/>
				<FormattedMessage id="why.how.title" tagName="h2"/>
				<FormattedMessage id="why.how.text" tagName="p"/>
			</div>
			<img src={powerline} alt="Powerline"/>
		</section>
	);
}

export default Why;
