import React from "react";
import { FormattedMessage } from 'react-intl';

import "./Contact.scss";
import telegram from './telegram.svg';
import mail from './mail.svg';
import skype from './skype.svg';
import discord from './discord.svg';
import bird from './bird.svg';

function Contact() {
	return (
		<section id="contact">
			<FormattedMessage id="contact.title" tagName="h1"/>
			<div id="bird-divider">
			<img src={bird} alt="Envelope Bird"/>
			</div>
			<div className="contact-area">
				<div className="methods">
					<a href="#contact" className="method">
						<img src={telegram} alt="telegram"/>
						<p>Telegram</p>
					</a>
					<a href="#contact" className="method">
						<img src={mail} alt="mail"/>
						<p>E-Mail</p>
					</a>
					<a href="#contact" className="method">
						<img src={skype} alt="skype"/>
						<p>Skype</p>
					</a>
					<a href="#contact" className="method">
						<img src={discord} alt="discord"/>
						<p>Discord</p>
					</a>
				</div>
				<form>
					<FormattedMessage id="contact.name">
						{ placeholder => <input placeholder={placeholder}/> }
					</FormattedMessage>
					<FormattedMessage id="contact.email">
						{ placeholder => <input placeholder={placeholder}/> }
					</FormattedMessage>
					<FormattedMessage id="contact.about">
						{ placeholder => <textarea placeholder={placeholder}></textarea> }
					</FormattedMessage>
					<button>
						<FormattedMessage id="contact.send"/>
					</button>
				</form>
			</div>
		</section>
	);
}

export default Contact;
