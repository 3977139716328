export default [
	{
		image: require("../images/testimonials/placeholder.png"),
		quote: {
			en: "I've never worked with a team as responsive as them before.",
			es: "No hablo español."
		},
		name: "Cool Customer #1"
	},
	{
		image: require("../images/testimonials/placeholder.png"),
		quote: {
			en: "I've never worked with a team as responsive as them before.",
			es: "No hablo español."
		},
		name: "Cool Customer #2"
	},
	{
		image: require("../images/testimonials/placeholder.png"),
		quote: {
			en: "I've never worked with a team as responsive as them before.",
			es: "No hablo español."
		},
		name: "Cool Customer #3"
	}
];
