import categories from './categories';
import prjs from './projects';
import tags from './tags';
import testimonials from './testimonials';

const projects = prjs.map((project, i) => {
	project.id = i;
	return project;
});

export {
	categories,
	projects,
	tags,
	testimonials
}

const _get = require('lodash/get');
export const getLanguageMessages = function (lang) {
	const messages = {};

	for(let i=0; i<testimonials.length; i++) {
		messages[`testimonials.quote.${i}`] = _get(testimonials, [i, 'quote', lang], 'No Quote!');
	}

	const tagKeys = Object.keys(tags);
	for(let i=0; i<tagKeys.length; i++) {
		messages[`projects.tags.${tagKeys[i]}`] = _get(tags, [tagKeys[i], lang], 'No Tag!');
	}

	for(let i=0; i<categories.length; i++) {
		messages[`projects.category.${i}`] = _get(categories, [i, 'title', lang], 'No Title!');
	}

	for(let i=0; i<projects.length; i++) {
		messages[`projects.name.${i}`] = _get(projects, [i, 'name', lang], 'No Name!');
	}

	return messages;
}
