import React, { useState } from "react";
import { Link } from "react-scroll";
import { FormattedMessage } from "react-intl";

import "./Navigation.scss";
import miniLogo from "./miniLogo.svg";

function Navigation() {
	const [showLinksState, setShowLinks] = useState(false);
	const linkProperties = {
		offset: -75,
		smooth: true,
		spy: true
	};

	window.addEventListener('scroll', function () {
		const htmlEl = document.getElementsByTagName('html')[0];
		const splashEl = document.getElementById('splash');
		const shouldBeFixed = splashEl.getBoundingClientRect().bottom < 0;

		if (shouldBeFixed) {
			if (!htmlEl.classList.contains('fixed-header')) {
				htmlEl.classList.add('fixed-header');
			}
		} else {
			if (htmlEl.classList.contains('fixed-header')) {
				htmlEl.classList.remove('fixed-header');
			}
		}
	});

	return (
		<>
			<div className="full-nav">
				<Link to="home" {...linkProperties}>
					<FormattedMessage id="nav.home" />
				</Link>
				<Link to="services" {...linkProperties}>
					<FormattedMessage id="nav.services" />
				</Link>
				<Link to="why" {...linkProperties}>
					<FormattedMessage id="nav.why" />
				</Link>
				<Link to="portfolio" {...linkProperties}>
					<FormattedMessage id="nav.portfolio" />
				</Link>
				<Link to="customers" {...linkProperties}>
					<FormattedMessage id="nav.customers" />
				</Link>
				<Link to="contact" {...linkProperties}>
					<FormattedMessage id="nav.contact" />
				</Link>
			</div>
			<div className="mobile-nav">
				<div className="bar">
					<img src={miniLogo} alt="Logo" onClick={() => setShowLinks(!showLinksState)}/>
				</div>
				<div className={`links ${showLinksState ? "visible" : ""}`}>
					<Link to="home" onClick={() => setShowLinks(false)} {...linkProperties}>
						<FormattedMessage id="nav.home" />
					</Link>
					<Link to="services" onClick={() => setShowLinks(false)} {...linkProperties}>
						<FormattedMessage id="nav.services" />
					</Link>
					<Link to="why" onClick={() => setShowLinks(false)} {...linkProperties}>
						<FormattedMessage id="nav.why" />
					</Link>
					<Link to="portfolio" onClick={() => setShowLinks(false)} {...linkProperties}>
						<FormattedMessage id="nav.portfolio" />
					</Link>
					<Link to="customers" onClick={() => setShowLinks(false)} {...linkProperties}>
						<FormattedMessage id="nav.customers" />
					</Link>
					<Link to="contact" onClick={() => setShowLinks(false)} {...linkProperties}>
						<FormattedMessage id="nav.contact" />
					</Link>
				</div>
			</div>
		</>
	);
}

export default Navigation;
