import _merge from 'lodash/merge';

import en from './en';
import es from './es';
import { getLanguageMessages } from '../content';

export default {
	en: _merge(en, getLanguageMessages('en')),
	es: _merge(es, getLanguageMessages('es'))
}

export const languages = [
	{
		id: 'en',
		name: en.id
	},
	{
		id: 'es',
		name: es.id
	}
];

console.log({
	en: _merge(en, getLanguageMessages('en')),
	es: _merge(es, getLanguageMessages('es'))
});
