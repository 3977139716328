export default {
	"id": "Español",
	"title": "HardFork",
	"title.post-text": "multimedia studio",
	"nav.home": "Home",
	"nav.services": "Our Services",
	"nav.why": "Why Hard Fork?",
	"nav.portfolio": "Portfolio",
	"nav.customers": "Our Customers",
	"nav.contact": "Contact",
	"home.header": "Hello!",
	"home.welcome": "Bienvenido a Hard Fork!",
	"home.text": "Somos un estudio especializado la más ampila gama de soluciones gráficas en un mercado donde las teecnologías descentralizadas avanzan día a día y es necesario comprenderlas para poder comunicar eficazmente.",
	"home.recent-projects": "Recent Projects",
	"home.back": "Back",
	"home.next": "Next",
	"services.title": "Lo que ofrecemos",
	"services.design.title": "Design & Marketing",
	"services.design.p1": "Diseño y mercadeo",
	"services.design.p2": "Diseño UX / UI",
	"services.design.p3": "Paquetes de inicio de negocios",
	"services.design.p4": "Campañas de marketing",
	"services.dev.title": "Web/App Design",
	"services.dev.p1": "Website/App Layout",
	"services.dev.p2": "Branding consultation",
	"services.dev.p3": "Start-up business packages",
	"services.dev.p4": "Rebranding/brand revision",
	"services.video.title": "Video Editing and Animation",
	"services.video.p1": "Brand identity design",
	"services.video.p2": "Branding consultation",
	"services.video.p3": "Start-up business packages",
	"services.video.p4": "Rebranding/brand revision",
	"services.photo.title": "Illustration & Photo Editing",
	"services.photo.p1": "Brand identity design",
	"services.photo.p2": "Branding consultation",
	"services.photo.p3": "Start-up business packages",
	"services.photo.p4": "Rebranding/brand revision",
	"portfolio.title": "Our Portfolio",
	"portfolio.type.all": "All",
	"portfolio.type.branding": "Branding",
	"portfolio.type.editorial": "Editorial",
	"portfolio.type.illustration": "Illustration",
	"portfolio.type.games": "Games",
	"portfolio.type.video": "Animation/Video",
	"portfolio.back": "Back",
	"portfolio.next": "Next",
	"why.why.title": "Why Hard Fork?",
	"why.why.text": "In Hard Fork we understand that the decentralized ledger technologies are starting a revolution in the way the people connect with others and how the world is creating a new era for the internet.",
	"why.what.title": "So What?",
	"why.what.text": "With many years working with different startups we have reached a huge experience in the industry of blockchain, cryptos and DLT. It gives us a wide knowledge in those fields, that help us understand complex projects and communicate for them in the more effecient way.",
	"why.how.title": "How that helps my business?",
	"why.how.text": "Having a previous experience with the sort of work we are dealing with we can offer a faster and moree effective feedback, and also, a clear and simple way to communicatee your ideas to your customers.",
	"customers.title.before": "Know",
	"customers.title": "Our Customers",
	"customers.text": "Many startup and established businesses have had a great time working with us.",
	"customers.text.after": "Take a look at them!",
	"contact.title": "Contact us",
	"contact.name": "Name",
	"contact.email": "E-Mail Address",
	"contact.about": "Tell us about your project :)",
	"contact.send": "Send",
	"footer.language": "Language",
	"footer.privacy": "Privacy Notice",
	"footer.terms": "Terms of Service",
}
